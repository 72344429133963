<template>
    <div class="login-pg">
        <div class="login-form">
            <img class="mb-5" src="../../../public/img/logo_light.png" alt="" />
            <el-form ref="form" :model="form" :rules="rules">
                <div class="app-form__group mb-5">
                    <el-form-item prop="phone">
                        <el-input
                            v-model="form.phone"
                            placeholder="+998"
                            prefix-icon="el-icon-user"
                            @keyup.enter.native="login()"
                        ></el-input>
                    </el-form-item>
                </div>
                <div class="app-form__group mb-5">
                    <el-form-item prop="password">
                        <el-input
                            placeholder="Пароль"
                            v-model="form.password"
                            type="password"
                            prefix-icon="el-icon-lock"
                            @keyup.enter.native="login()"
                            show-password
                        ></el-input>
                    </el-form-item>
                </div>
                <el-button
                    type="success"
                    @click="login()"
                    :loading="loading"
                    plain
                    >{{$t('message.login')}}</el-button
                >
            </el-form>
        </div>
        <!-- <bdo dir="rtl"> Текст будет отображен справа налево </bdo> -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "login",
    data() {
        return {
            user: [],
            phone: "",
            form: {
                password: "",
                phone: "+",
            },
            validCredentials: {
                username: "lightscope",
                password: "lightscope",
            },
            valid: {
                phone: true,
                confirm_code: false,
                password: false,
            },
            model: {
                valid_phone: "",
                confirm_code: null,
                password: "",
                confirm_password: "",
            },
            loading: false,
            loading_validate: false,
            dialogVisible: false,
            rules: {
                phone: [
                    {
                        required: true,
                        message: this.$t('message.please_enter_input', {input: this.$t('message.phone')}),
                        trigger: "blur",
                    },
                    {
                        min: 13,
                        max: 13,
                        message: this.$t('message.length_must_be', {number: 13}),
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: this.$t('message.please_enter_input', {input: this.$t('message.password')}),
                        trigger: "blur",
                    },
                    {
                        min: 5,
                        message: this.$t('message.length_must_be_min', {number: 5}),
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                const query = route.query;
                if (query) {
                    this.redirect = query.redirect;
                    this.otherQuery = this.getOtherQuery(query);
                }
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            token: "auth/token",
        }),
    },
    methods: {
        ...mapActions({
            postLogin: "auth/login",
        }),
        getOtherQuery(query) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== "redirect") {
                    acc[cur] = query[cur];
                }
                return acc;
            }, {});
        },
        login() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.postLogin(this.form)
                        .then((res) => {
                            this.loading = false;
                            this.$router.push({
                                path: this.redirect || "/",
                                query: this.otherQuery,
                            });
                        })
                        .catch((error) => {
                            this.$alert(error);
                            this.loading = false;
                        });
                }
            });
        },
        Message_Success(message) {
            this.$notify.success({
                dangerouslyUseHTMLString: true,
                title: "Success",
                message: "<strong>" + message + "</strong>",
                duration: 6000,
            });
        },
        Message_Error(code, message) {
            this.$notify.error({
                dangerouslyUseHTMLString: true,
                title: "Error " + code,
                message: "<strong>" + message + "</strong>",
                position: "top-right",
            });
        },
    },
};
</script>




<style lang="scss">
.login-pg {
    overflow: hidden;
    height: 100vh;
    width: 100%;
    background-image: url(../../../public/img/login__img/bg-img.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 400px;

    form {
        padding: 30px 20px;
        background-color: #00000054;
        border-radius: 10px;
    }

    .el-input__inner {
        border-radius: 50px;
        background-color: inherit;
        color: #fff;
    }
    .el-input__icon {
        color: #fff;
    }
    .el-input__icon::placeholder {
        color: #fff;
        opacity: 1; /* Firefox */
    }

    .el-input__icon:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
    }

    .el-input__icon::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff;
    }

    .el-button--success.is-plain {
        color: #fff;
        width: 100%;
        background: #f0f9eb47;
        border-radius: 50px;
    }
}
</style>
